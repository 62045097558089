import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UploadView.css';
import MatchingHanger from './MatchingHanger';

const UploadView = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numHoles, setNumHoles] = useState('');
  const [hangerResults, setHangerResults] = useState([]);
  const [showingResults, setShowingResults] = useState([]);
  const [error, setError] = useState(null);
  const [uploadTimestamp, setUploadTimestamp] = useState(null);

//  const baseUrl = 'https://bikes.techlara.se/api/';
  const baseUrl = 'http://35.159.220.212/';

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const numHolesParam = searchParams.get('numHoles');
    if (numHolesParam) {
      setNumHoles(numHolesParam);
    }
    setShowingResults(hangerResults);
    console.log("effect");
  }, [hangerResults]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('numHoles', numHoles);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(baseUrl + 'upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      console.log('Full response:', response);
      if (response.data && Array.isArray(response.data)) {
        setHangerResults(response.data);
        setUploadTimestamp(Date.now()); 
      } else {
        console.error('Unexpected response format:', response.data);
        
        setError('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setError('An error occurred during upload. Please try again.', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="upload-view">
        <h1>Ladda upp en bild på ditt växelöra</h1>
        <div className="upload-container">
          <input type="file" onChange={handleFileChange} />
          <button className='button' onClick={handleUpload} disabled={loading}>
            {loading ? 'Laddar upp...' : 'Ladda upp'}
          </button>
        </div>
      </div>

      <div className="result-container">
        {loading && <p>Laddar upp... Det tar upp till 20 sekunder</p>}
        {error && <p className="error-message">{error}</p>}
        {showingResults && hangerResults.length > 0 && (
          <div>
            <div className="your-hanger">
              <h2 className="hanger-header">Ditt växelöra</h2>
              <img 
        src={`${baseUrl}uploads/fixed_image.jpg?t=${uploadTimestamp}`} 
        alt="Ditt växelöra"
        className="fixed-image" 
      />
            </div>
          <div className="matching-hangers-section">
          <h2 className="hanger-header">Matchande växelöron</h2>
            <div className="hangers-container">
              {showingResults.map((result, index) => (
                <div key={index} className="hanger-item">
                  <MatchingHanger resultHanger={result} />
                </div>
              ))}
            </div>
          </div>
          </div>
        )}

        {showingResults.length < hangerResults.length && (
          <button
            className="button show-more-button"
            onClick={() => setShowingResults(hangerResults.slice(0, showingResults.length + 3))}
          >
            Visa fler
          </button>
        )}
      </div>
    </div>
  );
}

export default UploadView;